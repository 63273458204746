import React from "react";
import Leaderboards from "./Leaderboards";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import Guts from "./Guts";
import Visualization from "./Visualization";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile/:name" element={<Profile />} />
        <Route path="/leaderboards" element={<Leaderboards />} />
        <Route path="/visualizations" element={<Visualization />} />
        <Route path="/guts" element={<Guts />} />
      </Routes>
    </Router>
  );
}

export default App;
