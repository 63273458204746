import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";

function Home() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchInputRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const updateDropdownPosition = () => {
      if (searchInputRef.current) {
        const rect = searchInputRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
        });
      }
    };

    updateDropdownPosition();
    window.addEventListener("scroll", updateDropdownPosition);

    return () => {
      window.removeEventListener("scroll", updateDropdownPosition);
    };
  }, []);

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      try {
        const response = await fetch(`/api/search?q=${query}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSearchResults(data.slice(0, 5));
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`/api/search?q=${searchQuery}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleResultClick = (name) => {
    navigate(`/profile/${name}`);
    setSearchQuery("");
  };

  return (
    <div className="Home">
      <Header
        searchQuery={searchQuery}
        searchResults={searchResults}
        searchInputRef={searchInputRef}
        dropdownPosition={dropdownPosition}
        handleSearchChange={handleSearchChange}
        handleSearchSubmit={handleSearchSubmit}
        handleResultClick={handleResultClick}
      />
      <div className="home-content profile-card">
        <h1 className="home-title">Frontier League Savant</h1>
        <p className="home-description">
          The hub for all Frontier League advanced stats, models, and
          visualizations.
        </p>
        <div className="creator-info">
          <span>Created by Jack Kelly</span>
        </div>
        <div className="social-links">
          <a
            href="https://twitter.com/jkelly_1214"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={28} />
          </a>
          <a
            href="https://www.linkedin.com/in/jack-kelly-64b813225/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={28} />
          </a>
          <a href="mailto:kellyjc@rose-hulman.edu">
            <FaEnvelope size={28} />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
