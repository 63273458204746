import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../App.css";

function Header() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (searchInputRef.current) {
      const rect = searchInputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }

    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        setSelectedResultIndex((prevIndex) =>
          Math.min(prevIndex + 1, searchResults.length - 1)
        );
      } else if (event.key === "ArrowUp") {
        setSelectedResultIndex((prevIndex) => Math.max(prevIndex - 1, -1));
      } else if (event.key === "Enter") {
        if (selectedResultIndex !== -1 && searchResults[selectedResultIndex]) {
          handleResultClick(searchResults[selectedResultIndex]);
          setSearchResults([]);
        }
      }
    };

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setSearchResults([]);
        setSelectedResultIndex(-1);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchResults, selectedResultIndex]);

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      try {
        const response = await fetch(`/api/search?q=${query}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSearchResults(data.slice(0, 5));
        setSelectedResultIndex(-1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setSearchResults([]);
      setSelectedResultIndex(-1);
      setSearchQuery("");
    }
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`/api/search?q=${searchQuery}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSearchResults(data);
      setSelectedResultIndex(-1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setSearchQuery("");
  };

  const handleResultClick = (name) => {
    navigate(`/profile/${name}`);
    setSearchResults([]);
    setSelectedResultIndex(-1);
    setSearchQuery("");
  };

  const handleLogoClick = () => {
    navigate("/");
    setSearchQuery("");
  };

  return (
    <header className="Home-header">
      <div className="header-content">
        <div className="header-left">
          <h1 style={{ cursor: "pointer" }} onClick={handleLogoClick}>
            FLSavant
          </h1>
          <nav className="nav-tabs">
            <Link to="/leaderboards" className="tab">
              Leaderboards
            </Link>
            <Link to="/visualizations" className="tab">
              Visuals
            </Link>
            <Link to="/guts" className="tab">
              Guts
            </Link>
          </nav>
        </div>
        <div className="header-right">
          <form className="search-form" onSubmit={handleSearchSubmit}>
            <input
              ref={searchInputRef}
              type="text"
              className="search-input"
              placeholder="Search for player..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </form>
        </div>
      </div>
      <hr className="header-line" />
      {searchResults.length > 0 && (
        <ul
          className="search-results"
          style={{
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            width: dropdownPosition.width,
          }}
          ref={dropdownRef}
        >
          {searchResults.map((result, index) => (
            <li
              key={index}
              onClick={() => handleResultClick(result)}
              className={index === selectedResultIndex ? "selected" : ""}
            >
              {result}
            </li>
          ))}
        </ul>
      )}
    </header>
  );
}

export default Header;
