import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Guts.css";

const Guts = () => {
  const [weights, setWeights] = useState([]);
  const [runExpectancy, setRunExpectancy] = useState([]);
  const [parkFactors, setParkFactors] = useState([]);
  const [activeTab, setActiveTab] = useState("constants");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const constantsColumns = [
    "Season",
    "w1B",
    "w2B",
    "w3B",
    "wHR",
    "wBB",
    "wHBP",
    "wSB",
    "wCS",
    "wOBA",
    "wOBAScale",
    "FIP",
    "cFIP",
    "R/PA",
    "R/W",
  ];

  const parkFactorsColumns = [
    "Season",
    "Team",
    "PF",
    "1B",
    "2B",
    "3B",
    "HR",
    "SO",
  ];

  const reColumns = ["Season", "State", "RE"];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [weightsResponse, parkFactorsResponse, runExpectancyResponse] =
        await Promise.all([
          fetch("/api/weights"),
          fetch("/api/park_factors"),
          fetch("/api/run_expectancy"),
        ]);

      console.log(runExpectancyResponse);

      if (!weightsResponse.ok || !parkFactorsResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const weightsData = await weightsResponse.json();
      const parkFactorsData = await parkFactorsResponse.json();
      const runExpectancyData = await runExpectancyResponse.json();

      setWeights(roundData(weightsData, constantsColumns));
      setRunExpectancy(roundData(runExpectancyData, reColumns));
      setParkFactors(roundParkFactors(parkFactorsData));
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const roundData = (data, columns) => {
    return data.map((row) => {
      const roundedRow = {};
      columns.forEach((key) => {
        if (key in row) {
          if (key === "Season" || key === "Team") {
            roundedRow[key] = row[key];
          } else if (typeof row[key] === "number") {
            roundedRow[key] = Number(row[key].toFixed(3));
          } else {
            roundedRow[key] = row[key];
          }
        }
      });
      return roundedRow;
    });
  };

  const roundParkFactors = (data) => {
    return data.map((row) => {
      const roundedRow = { ...row };
      parkFactorsColumns.forEach((key) => {
        if (
          key !== "Season" &&
          key !== "Team" &&
          typeof row[key] === "number"
        ) {
          roundedRow[key] = Math.round(row[key]);
        }
      });
      return roundedRow;
    });
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedParkFactors = [...parkFactors].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const getTextColor = (backgroundColor) => {
    const rgb = backgroundColor.match(/\d+/g).map(Number);
    const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
    return luminance > 0.5 ? "black" : "white";
  };

  const getColorStyle = (value, min = 70, max = 130, mid = 100) => {
    let backgroundColor;

    if (value > mid) {
      const intensity = Math.round(255 * (1 - (value - mid) / (max - mid)));
      backgroundColor = `rgb(255, ${intensity}, ${intensity})`;
    } else if (value < mid) {
      const intensity = Math.round(255 * (1 - (mid - value) / (mid - min)));
      backgroundColor = `rgb(${intensity}, ${intensity}, 255)`;
    } else {
      backgroundColor = "rgb(255, 255, 255)";
    }

    const textColor = getTextColor(backgroundColor);

    return {
      color: textColor,
      backgroundColor: backgroundColor,
    };
  };

  const renderTable = (data, columns, isSortable = false) => (
    <div className="data-table">
      <table>
        <thead>
          <tr>
            {columns.map((key) => (
              <th key={key} onClick={() => isSortable && handleSort(key)}>
                {key}
                {isSortable && sortColumn === key && (
                  <span>{sortDirection === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((key) => (
                <td
                  key={key}
                  style={
                    activeTab === "parkFactors" &&
                    key !== "Season" &&
                    key !== "Team"
                      ? getColorStyle(row[key])
                      : {}
                  }
                >
                  {row[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="Guts">
      <Header />
      <main className="content">
        <div className="tab-container">
          <button
            className={`tab ${activeTab === "constants" ? "active" : ""}`}
            onClick={() => setActiveTab("constants")}
          >
            wOBA & FIP Constants
          </button>
          <button
            className={`tab ${activeTab === "parkFactors" ? "active" : ""}`}
            onClick={() => setActiveTab("parkFactors")}
          >
            Park Factors
          </button>
          <button
            className={`tab ${activeTab === "runExpectancy" ? "active" : ""}`}
            onClick={() => setActiveTab("runExpectancy")}
          >
            Run Expenctancy
          </button>
        </div>
        <section className="data-section">
          {isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : activeTab === "constants" ? (
            renderTable(weights, constantsColumns)
          ) : activeTab === "parkFactors" ? (
            renderTable(sortedParkFactors, parkFactorsColumns, true)
          ) : (
            renderTable(runExpectancy, reColumns)
          )}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Guts;
