import React from "react";
import "../Footer.css";

const glossaryTerms = [
  {
    term: "Expected Batting Average (xBA)",
    definition:
      "xBA measures the likelihood that a batted ball will become a hit.",
  },
  {
    term: "Expected Weighted On-base Average (xwOBA)",
    definition:
      "xwOBA is a probability based model that uses up-to-date weights and multiplies each result probability for each batted ball.",
  },
  {
    term: "EV50",
    definition:
      "For pitchers, the average EV of the bottom half of batted balls; for hitters, the average EV of the top half of the player's batted balls.",
  },
  {
    term: "Front Row Joe",
    definition:
      "A home run hit by a player that had a less than 60% probabilty of getting out, based on park factors, EV, and LA.",
  },
  {
    term: "Z-Contact%",
    definition:
      "In-zone contact rate. Similarly, Z-Swing% is the rate at which hitters swing at pitches that are in the zone.",
  },
  {
    term: "RV/100",
    definition:
      "A pitcher's run value by pitch type prorated to 100 pitches, where positive is better.",
  },
  {
    term: "SweetSpot%",
    definition:
      'The percentage of batted balls either for a hitter of against a pitcher that are in the "sweet spot" of launch angle (8 to 32 degrees).',
  },
  {
    term: "Barrel%",
    definition:
      'The percentage of batted balls either for a hitter of against a pitcher that were "barreled." Barrels are defined by MLB as batted balls that have the ideal EV and LA.',
  },
];

const Footer = () => {
  return (
    <footer className="statcast-footer flex flex-col items-center">
      <hr className="w-full" />
      <div className="glossary-grid w-full">
        {glossaryTerms.map((item, index) => (
          <div key={index} className="glossary-item visualization-card">
            <h3>{item.term}</h3>
            <p>{item.definition}</p>
          </div>
        ))}
      </div>
      <div className="logo-container mt-4">
        <img src="/logo192.png" width={50} height={50} />
      </div>
    </footer>
  );
};

export default Footer;
