import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as d3 from "d3";
import Footer from "./components/Footer";
import "./App.css";
import Header from "./components/Header";

function Profile() {
  const { name } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const clearCharts = () => {
    d3.select("#percentileChart").selectAll("*").remove();
    d3.select("#chart").selectAll("*").remove();
    d3.select("#otherChart").selectAll("*").remove();
    d3.select(".tables").selectAll("*").remove();
    d3.select(".similar-hitters-container").remove();
    d3.select(".similar-pitchers-container").remove();
  };

  const handlePitcherData = (
    savantData,
    veloData,
    locationData,
    arsenalData,
    pitchTrackingPitcherData,
    brefData,
    spinData,
    stuffData,
    spinChartsData
  ) => {
    createPitcherPercentiles(savantData.pitch_data[0]);
    createYakkerTablePitcher(savantData.pitch_data[0]);
    createVeloChart(veloData.velo_data, veloData.league_mean_velos);
    createLocationCharts(locationData.location_data);
    createPitchPlot(arsenalData.arsenal_data);
    createPitchTrackingPitcherTable(pitchTrackingPitcherData.pitch_data);
    createProfilePitcher(JSON.parse(brefData.pitching));
    displaySimilarPitchers(savantData.similar_pitchers);
    createSpinTable(spinData.data);
    createSpinCharts(spinChartsData.data);
    createStuffTable(stuffData.data);
  };

  const handleHitterData = (
    savantData,
    sprayChartData,
    plateDisciplineData,
    bbProfileData,
    xHRData,
    pitchTrackingData,
    hitTypeData,
    brefData,
    swingTakeData
  ) => {
    createHitterPercentiles(savantData.hit_data[0]);
    createYakkerTableHitter(savantData.hit_data[0]);
    createSpraychart(sprayChartData.spray_data);
    createPlateDiscTable(plateDisciplineData.data[0]);
    createPitchTrackingTable(pitchTrackingData.hit_data);
    createBBProfileTable(bbProfileData.data[0]);
    createxHRTable(xHRData.data[0]);
    createHitTypeChart(hitTypeData.data);
    const hittingData = JSON.parse(brefData.hitting);
    createProfileHitter(hittingData);
    displaySimilarHitters(savantData.similar_hitters);
    createSwingTakeTable(swingTakeData.data[0]);
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        const [
          savantResponse,
          sprayChartResponse,
          veloResponse,
          locationResponse,
          plateDisciplineResponse,
          bbProfileResponse,
          xHRResponse,
          arsenalResponse,
          pitchTrackingResponse,
          hitTypeResponse,
          brefResponse,
          pitchTrackingPitcherResponse,
          spinResponse,
          stuffResponse,
          swingTakeResponse,
          yakkerResponse,
        ] = await Promise.all([
          fetch(`/api/savant?q=${name}`),
          fetch(`/api/spraychart?q=${name}`),
          fetch(`/api/velo?q=${name}`),
          fetch(`/api/location?q=${name}`),
          fetch(`/api/plateDiscipline?q=${name}`),
          fetch(`/api/battedBallProfile?q=${name}`),
          fetch(`/api/xHR?q=${name}`),
          fetch(`/api/arsenal?q=${name}`),
          fetch(`/api/pitchTracking?q=${name}`),
          fetch(`/api/hitType?q=${name}`),
          fetch(`/api/bref?q=${name}`),
          fetch(`/api/pitchTrackingPitchers?q=${name}`),
          fetch(`/api/spinData?q=${name}`),
          fetch(`/api/stuffData?q=${name}`),
          fetch(`/api/swingTake?q=${name}`),
          fetch(`/api/spinCharts?q=${name}`),
        ]);

        const [
          savantData,
          sprayChartData,
          veloData,
          locationData,
          plateDisciplineData,
          bbProfileData,
          xHRData,
          arsenalData,
          pitchTrackingData,
          hitTypeData,
          brefData,
          pitchTrackingPitcherData,
          spinData,
          stuffData,
          swingTakeData,
          spinChartsData,
        ] = await Promise.all([
          savantResponse.json(),
          sprayChartResponse.json(),
          veloResponse.json(),
          locationResponse.json(),
          plateDisciplineResponse.json(),
          bbProfileResponse.json(),
          xHRResponse.json(),
          arsenalResponse.json(),
          pitchTrackingResponse.json(),
          hitTypeResponse.json(),
          brefResponse.json(),
          pitchTrackingPitcherResponse.json(),
          spinResponse.json(),
          stuffResponse.json(),
          swingTakeResponse.json(),
          yakkerResponse.json(),
        ]);

        clearCharts();

        if (
          savantData.hit_data &&
          Array.isArray(savantData.hit_data) &&
          savantData.hit_data.length > 0
        ) {
          handleHitterData(
            savantData,
            sprayChartData,
            plateDisciplineData,
            bbProfileData,
            xHRData,
            pitchTrackingData,
            hitTypeData,
            brefData,
            swingTakeData
          );
        } else if (
          savantData.pitch_data &&
          Array.isArray(savantData.pitch_data) &&
          savantData.pitch_data.length > 0
        ) {
          handlePitcherData(
            savantData,
            veloData,
            locationData,
            arsenalData,
            pitchTrackingPitcherData,
            brefData,
            spinData,
            stuffData,
            spinChartsData
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [name]);

  const displaySimilarHitters = (similarHitters) => {
    if (!similarHitters || similarHitters.length === 0) return;

    const profileCard = document.querySelector(".profile-card");
    const existingSimilarHitters = profileCard.querySelector(
      ".similar-hitters-container"
    );
    if (existingSimilarHitters) profileCard.removeChild(existingSimilarHitters);

    const container = document.createElement("div");
    container.classList.add("similar-hitters-container");

    const title = document.createElement("h3");
    title.classList.add("similar-hitters-title");
    title.textContent = "Player Comparisons";

    const subtitle = document.createElement("p");
    subtitle.classList.add("similar-hitters-subtitle");
    subtitle.textContent =
      "Most similar hitters based on 2024 season performance:";

    const hitterslist = document.createElement("ul");
    hitterslist.classList.add("similar-hitters-list");

    similarHitters.forEach((hitter) => {
      const listItem = document.createElement("li");
      const link = document.createElement("a");
      link.href = "#";
      link.textContent = hitter;
      link.addEventListener("click", (e) => {
        e.preventDefault();
        navigate(`/profile/${encodeURIComponent(hitter)}`);
      });
      listItem.appendChild(link);
      hitterslist.appendChild(listItem);
    });

    container.appendChild(title);
    container.appendChild(subtitle);
    container.appendChild(hitterslist);
    profileCard.appendChild(container);
  };

  const displaySimilarPitchers = (similarPitchers) => {
    if (!similarPitchers || similarPitchers.length === 0) return;

    const profileCard = document.querySelector(".profile-card");
    const existingSimilarPitchers = profileCard.querySelector(
      ".similar-pitchers-container"
    );
    if (existingSimilarPitchers)
      profileCard.removeChild(existingSimilarPitchers);

    const container = document.createElement("div");
    container.classList.add("similar-pitchers-container");

    const title = document.createElement("h3");
    title.classList.add("similar-pitchers-title");
    title.textContent = "Player Comparisons";

    const subtitle = document.createElement("p");
    subtitle.classList.add("similar-pitchers-subtitle");
    subtitle.textContent =
      "Most similar pitchers based on 2024 season performance:";

    const pitchersList = document.createElement("ul");
    pitchersList.classList.add("similar-pitchers-list");

    similarPitchers.forEach((pitcher) => {
      const listItem = document.createElement("li");
      const link = document.createElement("a");
      link.href = "#";
      link.textContent = pitcher;
      link.addEventListener("click", (e) => {
        e.preventDefault();
        navigate(`/profile/${encodeURIComponent(pitcher)}`);
      });
      listItem.appendChild(link);
      pitchersList.appendChild(listItem);
    });

    container.appendChild(title);
    container.appendChild(subtitle);
    container.appendChild(pitchersList);
    profileCard.appendChild(container);
  };

  const createPitcherPercentiles = (data) => {
    const margin = { top: 40, right: 20, bottom: 40, left: 20 };
    const width = 420 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;
    const numLines = 13;

    const getX = (percentile) => (width * percentile) / 100;
    const cy = height / (numLines + 1);

    const percentileData = [
      {
        label: "xERA",
        cx: getX(data["xERA_percentile"]).toFixed(2),
        cy: cy * 1 + 5,
        nonPercentileLabel: "xERA",
      },
      {
        label: "xBA",
        cx: getX(data["xBA_percentile"]).toFixed(3),
        cy: cy * 2 + 5,
        nonPercentileLabel: "xBA",
      },
      {
        label: "Fastball Speed",
        cx: getX(data["FastballVelo_percentile"]).toFixed(1),
        cy: cy * 3 + 5,
        nonPercentileLabel: "FastballVelo",
      },
      {
        label: "EV50",
        cx: getX(data["EV50_percentile"]).toFixed(1),
        cy: cy * 4 + 5,
        nonPercentileLabel: "EV50",
      },
      {
        label: "Chase%",
        cx: getX(data["Chase%_percentile"]).toFixed(1),
        cy: cy * 5 + 5,
        nonPercentileLabel: "Chase%",
      },
      {
        label: "Whiff%",
        cx: getX(data["Whiff%_percentile"]).toFixed(1),
        cy: cy * 6 + 5,
        nonPercentileLabel: "Whiff%",
      },
      {
        label: "K%",
        cx: getX(data["K%_percentile"]).toFixed(1),
        cy: cy * 7 + 5,
        nonPercentileLabel: "K%",
      },
      {
        label: "BB%",
        cx: getX(data["BB%_percentile"]).toFixed(1),
        cy: cy * 8 + 5,
        nonPercentileLabel: "BB%",
      },
      {
        label: "Barrel%",
        cx: getX(data["Barrel%_percentile"]).toFixed(1),
        cy: cy * 9 + 5,
        nonPercentileLabel: "Barrel%",
      },
      {
        label: "Hard Hit%",
        cx: getX(data["HardHit%_percentile"]).toFixed(1),
        cy: cy * 10 + 5,
        nonPercentileLabel: "HardHit%",
      },
      {
        label: "Sweet Spot%",
        cx: getX(data["SweetSpot%_percentile"]).toFixed(1),
        cy: cy * 11 + 5,
        nonPercentileLabel: "SweetSpot%",
      },
      {
        label: "GB%",
        cx: getX(data["GB%_percentile"]).toFixed(1),
        cy: cy * 12 + 5,
        nonPercentileLabel: "GB%",
      },
      {
        label: "Extension",
        cx: getX(data["Extension_percentile"]).toFixed(1),
        cy: cy * 13 + 5,
        nonPercentileLabel: "Extension",
      },
    ];

    const svg = d3
      .select("#percentileChart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const lineDecorators = [];
    for (let i = 1; i <= numLines; i++) {
      lineDecorators.push(
        { cx: getX(0), cy: cy * i + 5 },
        { cx: getX(50), cy: cy * i + 5 },
        { cx: getX(100), cy: cy * i + 5 }
      );
    }

    svg
      .selectAll("line")
      .data([...percentileData, ...lineDecorators])
      .enter()
      .append("line")
      .style("stroke", "rgb(155, 155, 155)")
      .style("stroke-width", 1)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => d.cy)
      .attr("y2", (d) => d.cy);

    svg
      .selectAll(".percentileLine")
      .data(percentileData)
      .enter()
      .append("line")
      .attr("class", "percentileLine")
      .style("stroke", (d) => d3.interpolateRdBu(1 - d.cx / width))
      .style("stroke-width", 3)
      .attr("x1", 0)
      .attr("x2", (d) => d.cx - 11.5)
      .attr("y1", (d) => d.cy)
      .attr("y2", (d) => d.cy);

    svg
      .selectAll("#decor")
      .data(lineDecorators)
      .enter()
      .append("circle")
      .attr("id", "decor")
      .attr("cx", (d) => d.cx)
      .attr("cy", (d) => d.cy)
      .attr("r", 3)
      .attr("fill", "rgb(155, 155, 155)");

    svg
      .selectAll(".ranks")
      .data(percentileData)
      .enter()
      .append("circle")
      .attr("class", "ranks")
      .attr("cx", (d) => d.cx)
      .attr("cy", (d) => d.cy)
      .attr("r", 11)
      .attr("fill", (d) => d3.interpolateRdBu(1 - d.cx / width))
      .attr("stroke", "black");

    svg
      .selectAll(".labels")
      .data(percentileData)
      .enter()
      .append("text")
      .attr("class", "labels")
      .attr("x", 5)
      .attr("y", (d) => d.cy - 14)
      .text((d) => d.label)
      .attr("font-size", ".9rem")
      .attr("font-family", "monospace")
      .attr("fill", "black");

    svg
      .selectAll(".percentRank")
      .data(percentileData)
      .enter()
      .append("text")
      .attr("class", "percentRank")
      .attr("x", (d) => d.cx)
      .attr("y", (d) => d.cy + 4)
      .attr("text-anchor", "middle")
      .text((d) => Math.floor((d.cx / width) * 100))
      .attr("font-weight", "bold")
      .attr("font-family", "monospace")
      .attr("font-size", ".8rem")
      .attr("fill", (d) =>
        Math.floor((d.cx / width) * 100) >= 70 ||
        Math.floor((d.cx / width) * 100) <= 30
          ? "white"
          : "black"
      );

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .style("font-size", "17px")
      .style("font-family", "monospace")
      .style("fill", "black")
      .text("2024 FL Pitcher Percentile Rankings");

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .selectAll(".percentRank")
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        let tooltipContent = "";

        if (["EV50", "FastballVelo"].includes(d.nonPercentileLabel)) {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(1)} mph`;
        } else if (["xBA", "xSLG"].includes(d.nonPercentileLabel)) {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(3)}`;
        } else if (["xERA"].includes(d.nonPercentileLabel)) {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(2)}`;
        } else if (d.nonPercentileLabel === "Extension") {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(1)} ft`;
        } else {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(1)}%`;
        }

        tooltip
          .html(tooltipContent)
          .style("left", event.pageX + 5 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });
  };

  const createHitterPercentiles = (data) => {
    const margin = { top: 40, right: 20, bottom: 40, left: 20 };
    const width = 420 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;
    const numLines = 13;

    const getX = (percentile) => (width * percentile) / 100;
    const cy = height / (numLines + 1);

    const percentileData = [
      {
        label: "xwOBA",
        cx: getX(data["xwOBA_percentile"]),
        cy: cy * 1 + 5,
        nonPercentileLabel: "xwOBA",
      },
      {
        label: "xBA",
        cx: getX(data["xBA_percentile"]),
        cy: cy * 2 + 5,
        nonPercentileLabel: "xBA",
      },
      {
        label: "xSLG",
        cx: getX(data["xSLG_percentile"]),
        cy: cy * 3 + 5,
        nonPercentileLabel: "xSLG",
      },
      {
        label: "EV50",
        cx: getX(data["EV50_percentile"]),
        cy: cy * 4 + 5,
        nonPercentileLabel: "EV50",
      },
      {
        label: "Chase%",
        cx: getX(data["Chase%_percentile"]),
        cy: cy * 5 + 5,
        nonPercentileLabel: "Chase%",
      },
      {
        label: "Whiff%",
        cx: getX(data["Whiff%_percentile"]),
        cy: cy * 6 + 5,
        nonPercentileLabel: "Whiff%",
      },
      {
        label: "K%",
        cx: getX(data["K%_percentile"]),
        cy: cy * 7 + 5,
        nonPercentileLabel: "K%",
      },
      {
        label: "BB%",
        cx: getX(data["BB%_percentile"]),
        cy: cy * 8 + 5,
        nonPercentileLabel: "BB%",
      },
      {
        label: "Barrel%",
        cx: getX(data["Barrel%_percentile"]),
        cy: cy * 9 + 5,
        nonPercentileLabel: "Barrel%",
      },
      {
        label: "Hard Hit%",
        cx: getX(data["HardHit%_percentile"]),
        cy: cy * 10 + 5,
        nonPercentileLabel: "HardHit%",
      },
      {
        label: "Sweet Spot%",
        cx: getX(data["SweetSpot%_percentile"]),
        cy: cy * 11 + 5,
        nonPercentileLabel: "SweetSpot%",
      },
      {
        label: "Z-Swing%",
        cx: getX(data["Z-Swing%_percentile"]),
        cy: cy * 12 + 5,
        nonPercentileLabel: "Z-Swing%",
      },
      {
        label: "Z-Contact%",
        cx: getX(data["Z-Contact%_percentile"]),
        cy: cy * 13 + 5,
        nonPercentileLabel: "Z-Contact%",
      },
    ];

    const svg = d3
      .select("#percentileChart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const lineDecorators = [];
    for (let i = 1; i <= numLines; i++) {
      lineDecorators.push(
        { cx: getX(0), cy: cy * i + 5 },
        { cx: getX(50), cy: cy * i + 5 },
        { cx: getX(100), cy: cy * i + 5 }
      );
    }

    svg
      .selectAll("line")
      .data([...percentileData, ...lineDecorators])
      .enter()
      .append("line")
      .style("stroke", "rgb(155, 155, 155)")
      .style("stroke-width", 1)
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => d.cy)
      .attr("y2", (d) => d.cy);

    svg
      .selectAll(".percentileLine")
      .data(percentileData)
      .enter()
      .append("line")
      .attr("class", "percentileLine")
      .style("stroke", (d) => d3.interpolateRdBu(1 - d.cx / width))
      .style("stroke-width", 3)
      .attr("x1", 0)
      .attr("x2", (d) => d.cx - 11.5)
      .attr("y1", (d) => d.cy)
      .attr("y2", (d) => d.cy);

    svg
      .selectAll("#decor")
      .data(lineDecorators)
      .enter()
      .append("circle")
      .attr("id", "decor")
      .attr("cx", (d) => d.cx)
      .attr("cy", (d) => d.cy)
      .attr("r", 3)
      .attr("fill", "rgb(155, 155, 155)");

    svg
      .selectAll(".ranks")
      .data(percentileData)
      .enter()
      .append("circle")
      .attr("class", "ranks")
      .attr("cx", (d) => d.cx)
      .attr("cy", (d) => d.cy)
      .attr("r", 11)
      .attr("fill", (d) => d3.interpolateRdBu(1 - d.cx / width))
      .attr("stroke", "black");

    svg
      .selectAll(".labels")
      .data(percentileData)
      .enter()
      .append("text")
      .attr("class", "labels")
      .attr("x", 5)
      .attr("y", (d) => d.cy - 14)
      .text((d) => d.label)
      .attr("font-size", ".9rem")
      .attr("font-family", "monospace")
      .attr("fill", "black");

    svg
      .selectAll(".percentRank")
      .data(percentileData)
      .enter()
      .append("text")
      .attr("class", "percentRank")
      .attr("x", (d) => d.cx)
      .attr("y", (d) => d.cy + 4)
      .attr("text-anchor", "middle")
      .text((d) => Math.floor((d.cx / width) * 100))
      .attr("font-weight", "bold")
      .attr("font-family", "monospace")
      .attr("font-size", ".8rem")
      .attr("fill", (d) =>
        Math.floor((d.cx / width) * 100) >= 70 ||
        Math.floor((d.cx / width) * 100) <= 30
          ? "white"
          : "black"
      );

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .style("font-size", "17px")
      .style("font-family", "monospace")
      .style("fill", "black")
      .text("2024 FL Hitter Percentile Rankings");

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .selectAll(".percentRank")
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        let tooltipContent = "";

        if (["EV50"].includes(d.nonPercentileLabel)) {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(1)} mph`;
        } else if (["xBA", "xSLG", "xwOBA"].includes(d.nonPercentileLabel)) {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(3)}`;
        } else {
          tooltipContent = `${d.nonPercentileLabel}: ${data[
            d.nonPercentileLabel
          ].toFixed(1)}%`;
        }

        tooltip
          .html(tooltipContent)
          .style("left", event.pageX + 5 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });
  };

  function createHitTypeChart(data) {
    d3.select("#otherChart").selectAll("*").remove();
    var margin = { top: 50, right: 20, bottom: 30, left: 50 },
      width = 420 - margin.left - margin.right,
      height = 300 - margin.top - margin.bottom,
      radius = Math.min(width, height) / 2;

    var svg = d3
      .select("#otherChart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (width / 2 + margin.left) +
          "," +
          (height / 2 + margin.top) +
          ")"
      );

    var color = d3.scaleOrdinal(d3.schemeCategory10);

    var pie = d3.pie().value(function (d) {
      return d.count;
    });

    var arc = d3
      .arc()
      .outerRadius(radius - 10)
      .innerRadius(0);

    var labelArc = d3
      .arc()
      .outerRadius(radius - 40)
      .innerRadius(radius - 40);

    // Aggregate data by HitType
    var hitTypeData = Array.from(
      d3.rollup(
        data,
        (v) => v.length,
        (d) => d.HitType
      ),
      ([key, value]) => ({ HitType: key, count: value })
    );

    var g = svg
      .selectAll(".arc")
      .data(pie(hitTypeData))
      .enter()
      .append("g")
      .attr("class", "arc");

    g.append("path")
      .attr("d", arc)
      .style("fill", function (d) {
        return color(d.data.HitType);
      });

    g.append("text")
      .attr("transform", function (d) {
        return "translate(" + labelArc.centroid(d) + ")";
      })
      .attr("dy", ".35em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-family", "monospace")
      .style("fill", "white")
      .text(function (d) {
        var total = d3.sum(
          hitTypeData.map(function (d) {
            return d.count;
          })
        );
        var percentage = ((d.data.count / total) * 100).toFixed(1) + "%";
        return percentage;
      });

    var legend = svg
      .append("g")
      .attr(
        "transform",
        "translate(" + (-(width / 2) + 10) + "," + (height / 2 - 50) + ")"
      );

    var legendItem = legend
      .selectAll(".legend-item")
      .data(hitTypeData)
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", function (d, i) {
        return "translate(0," + i * 20 + ")";
      });

    legendItem
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 4)
      .style("fill", function (d) {
        return color(d.HitType);
      })
      .style("stroke", "black")
      .style("stroke-width", "1px");

    legendItem
      .append("text")
      .attr("x", 8)
      .attr("y", 2)
      .attr("dy", ".3em")
      .style("text-anchor", "start")
      .style("font-family", "sans-serif")
      .attr("font-size", ".8rem")
      .text(function (d) {
        return d.HitType;
      });

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", -height / 2 - 20)
      .attr("text-anchor", "middle")
      .style("font-size", "17px")
      .style("font-family", "monospace")
      .style("fill", "black")
      .text("Hit Type Distribution");
  }

  function createSpraychart(data) {
    d3.select("#chart").selectAll("*").remove();

    const chartContainer = document.getElementById("chart");
    const containerWidth = chartContainer.clientWidth;
    const containerHeight = Math.min(containerWidth * 0.75, 300); // Maintain aspect ratio

    const margin = {
      top: containerHeight * 0.15,
      right: containerWidth * 0.05,
      bottom: containerHeight * 0.1,
      left: containerWidth * 0.12,
    };

    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const svg = d3
      .select("#chart")
      .append("svg")
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    var x = d3.scaleLinear().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);

    x.domain([0, 100]);
    y.domain([0, 100]);

    var colors = {
      Single: "orange",
      Double: "purple",
      Triple: "yellow",
      HomeRun: "pink",
    };

    var outer_field = [
      { x: 50, y: 0 },
      { x: 0, y: 50 },
      { x: 30, y: 90 },
      { x: 70, y: 90 },
      { x: 100, y: 50 },
    ];

    var diamond = [
      { x: 50, y: 10 },
      { x: 30, y: 30 },
      { x: 50, y: 50 },
      { x: 70, y: 30 },
    ];

    var left_triangle = [
      { x: -5, y: 0 },
      { x: -5, y: 55 },
      { x: 50, y: 0 },
    ];

    var right_triangle = [
      { x: 105, y: 0 },
      { x: 105, y: 55 },
      { x: 50, y: 0 },
    ];

    svg
      .selectAll("polygon")
      .data([outer_field])
      .enter()
      .append("polygon")
      .attr("points", function (d) {
        return d.map(function (d) {
          return [x(d.x), y(d.y)].join(",");
        });
      })
      .attr("fill", "#47681a");

    var points = [
      [x(50), y(0)],
      [x(15), y(20)],
      [x(50), y(55)],
      [x(85), y(20)],
      [x(50), y(0)],
    ];

    var lineGenerator = d3.line().curve(d3.curveNatural);
    var pathData4 = lineGenerator(points);
    svg.append("path").attr("d", pathData4).attr("fill", "#e5d3ae");

    svg
      .data([diamond])
      .append("polygon")
      .attr("points", function (d) {
        return d
          .map(function (d) {
            return [x(d.x), y(d.y)].join(",");
          })
          .join(" ");
      })
      .attr("fill", "#47681a");

    svg
      .data([left_triangle])
      .append("polygon")
      .attr("points", function (d) {
        return d.map(function (d) {
          return [x(d.x), y(d.y)].join(",");
        });
      })
      .attr("fill", "white");

    svg
      .data([right_triangle])
      .append("polygon")
      .attr("points", function (d) {
        return d.map(function (d) {
          return [x(d.x), y(d.y)].join(",");
        });
      })
      .attr("fill", "white");

    svg
      .selectAll(".bases")
      .data([
        { x: 30, y: 30 },
        { x: 70, y: 30 },
        { x: 50, y: 50 },
        { x: 50, y: 10 },
      ])
      .enter()
      .append("circle")
      .attr("r", 5)
      .attr("cx", function (d) {
        return x(d.x);
      })
      .attr("cy", function (d) {
        return y(d.y);
      })
      .style("fill", "white")
      .attr("class", "bases");

    svg
      .selectAll(".hit")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return x(d.x);
      })
      .attr("cy", function (d) {
        return y(d.y);
      })
      .attr("r", 5)
      .style("fill", function (d) {
        return colors[d.Desc];
      })
      .attr("class", "hits");

    var legend = svg
      .selectAll(".legend")
      .data(Object.keys(colors))
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", function (d, i) {
        return (
          "translate(" + (width - 375) + "," + (height - 20 - i * 20) + ")"
        );
      });
    legend
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 4)
      .style("fill", function (d) {
        return colors[d];
      })
      .style("stroke", "black")
      .style("stroke-width", "1px");

    legend
      .append("text")
      .attr("x", 8)
      .attr("y", 2)
      .attr("dy", ".3em")
      .style("text-anchor", "start")
      .style("font-family", "sans-serif")
      .attr("font-size", ".8rem")
      .text(function (d) {
        return d;
      });

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    svg
      .selectAll(".hits")
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        let tooltipContent = `Exit Velo: ${d.ExitSpeed.toFixed(
          1
        )} mph\nLaunch Angle: ${d.Angle.toFixed(1)}°`;

        tooltip
          .html(tooltipContent)
          .style("left", event.pageX + 5 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", -0)
      .attr("text-anchor", "middle")
      .style("font-size", "17px")
      .style("font-family", "monospace")
      .style("fill", "black")
      .text("2024 Spray Chart");
  }

  const colors = d3.scaleOrdinal(d3.schemeCategory10);

  const createVeloChart = (data, leagueMeanVelos) => {
    const nestedData = d3
      .groups(data, (d) => d.AutoPitchType)
      .map(([key, values]) => ({
        pitchType: key,
        velocities: values.map((d) => d.RelSpeed),
        usage: (values.length / data.length) * 100,
      }));

    const margin = { top: 40, right: 30, bottom: 50, left: 110 },
      width = 420 - margin.left - margin.right,
      height = 300 - margin.top - margin.bottom;

    const pitchTypeCount = nestedData.length;
    const plotHeight = height / pitchTypeCount;

    d3.select("#chart").select("svg").remove();

    const svg = d3
      .select("#chart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d) => d.RelSpeed) - 5,
        d3.max(data, (d) => d.RelSpeed) + 5,
      ])
      .range([0, width]);

    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .append("text")
      .attr("y", margin.bottom / 2)
      .attr("x", width / 2)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("Velocity (mph)");

    svg
      .append("text")
      .attr("x", width / 2 - 30)
      .attr("y", -20)
      .attr("text-anchor", "middle")
      .style("font-size", "17px")
      .style("font-family", "monospace")
      .style("fill", "black")
      .text("2024 Velocity Distribution");

    nestedData.forEach((group, i) => {
      const histogram = d3
        .histogram()
        .domain(x.domain())
        .thresholds(x.ticks(40))(group.velocities);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(histogram, (d) => d.length)])
        .range([plotHeight, 0]);

      const plotY = i * plotHeight;

      svg
        .append("path")
        .datum(histogram)
        .attr("fill", colors(group.pitchType))
        .attr("opacity", 0.4)
        .attr("transform", `translate(0,${plotY})`)
        .attr(
          "d",
          d3
            .area()
            .curve(d3.curveBasis)
            .x((d) => x(d.x0))
            .y0(plotHeight)
            .y1((d) => y(d.length))
        );

      svg
        .append("path")
        .datum(histogram)
        .attr("fill", "none")
        .attr("stroke", colors(group.pitchType))
        .attr("stroke-width", 1.5)
        .attr("stroke-linejoin", "round")
        .attr("transform", `translate(0,${plotY})`)
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveBasis)
            .x((d) => x(d.x0))
            .y((d) => y(d.length))
        );

      svg
        .append("text")
        .attr("x", -margin.left)
        .attr("y", plotY - 8 + plotHeight / 2)
        .attr("dy", "2.3em")
        .attr("text-anchor", "right")
        .attr("class", "label")
        .style("fill", "black")
        .attr("font-size", ".8rem")
        .text(`${group.usage.toFixed(1)}%`);

      svg
        .append("text")
        .attr("x", -margin.left + 40)
        .attr("y", plotY - 8 + plotHeight / 2)
        .attr("dy", "2.3em")
        .attr("text-anchor", "right")
        .attr("class", "label")
        .attr("font-size", ".8rem")
        .style("fill", colors(group.pitchType))
        .text(group.pitchType);

      const leagueMeanVelo = leagueMeanVelos[group.pitchType];
      if (leagueMeanVelo !== undefined) {
        svg
          .append("line")
          .attr("x1", x(leagueMeanVelo))
          .attr("x2", x(leagueMeanVelo))
          .attr("y1", plotY)
          .attr("y2", plotY + plotHeight)
          .attr("stroke", "skyblue")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "4 4");
      }
    });
  };

  const createPitchPlot = (data) => {
    if (!data || data.length === 0) {
      console.error("No data available for pitch plot.");
      return;
    }

    const margin = { top: 40, right: 30, bottom: 50, left: 70 },
      width = 420 - margin.left - margin.right,
      height = 300 - margin.top - margin.bottom;

    d3.select("#otherChart").select("svg").remove();

    const svg = d3
      .select("#otherChart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleLinear()
      .domain([
        -d3.max(data, (d) => Math.abs(d.HorzBreak)),
        d3.max(data, (d) => Math.abs(d.HorzBreak)),
      ])
      .range([0, width]);

    svg
      .append("g")
      .attr("transform", `translate(0,${height / 2})`)
      .call(d3.axisBottom(x))
      .append("text")
      .attr("y", margin.bottom / 2 + 5)
      .attr("x", width / 2 - 115)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("Horizontal Break (in.)");

    const y = d3
      .scaleLinear()
      .domain([
        -d3.max(data, (d) => Math.abs(d.InducedVertBreak)),
        d3.max(data, (d) => Math.abs(d.InducedVertBreak)),
      ])
      .range([height, 0]);

    svg
      .append("g")
      .attr("transform", `translate(${width / 2},0)`)
      .call(d3.axisLeft(y))
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left / 2 - 5)
      .attr("x", -height / 2 - 80)
      .attr("dy", ".75em")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("IVB (in.)");

    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(+d.HorzBreak))
      .attr("cy", (d) => y(+d.InducedVertBreak))
      .attr("r", 3)
      .style("fill", (d) => colors(d.AutoPitchType))
      .style("opacity", 0.7);

    svg
      .append("text")
      .attr("x", width / 2 - 20)
      .attr("y", -20)
      .attr("text-anchor", "middle")
      .style("font-size", "17px")
      .style("font-family", "monospace")
      .style("fill", "black")
      .text("2024 Pitch Plot");
  };

  const createLocationCharts = (data) => {
    const margin = { top: -20, right: 20, bottom: 0, left: 20 };
    const width = 275;
    const height = 250;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const filterButtons = ["All", "Early", "Ahead", "Behind"];
    let currentFilter = "All";

    const colors = d3.scaleOrdinal(d3.schemeCategory10);

    const filterData = (filter) => {
      switch (filter) {
        case "Early":
          return data.filter((d) =>
            ["0-0", "0-1", "1-0", "1-1"].includes(`${d.Balls}-${d.Strikes}`)
          );
        case "Ahead":
          return data.filter((d) =>
            ["0-2", "1-2", "2-2", "3-2"].includes(`${d.Balls}-${d.Strikes}`)
          );
        case "Behind":
          return data.filter((d) =>
            ["2-0", "3-0", "3-1", "2-1"].includes(`${d.Balls}-${d.Strikes}`)
          );
        default:
          return data;
      }
    };

    const updateCharts = (filter) => {
      currentFilter = filter;
      const filteredData = filterData(filter);

      // Update button styles
      d3.selectAll("button").classed("active", (d) => d === currentFilter);

      // Update summary text
      updateSummary(filteredData);

      // Update each pitch type chart
      updatePitchTypeCharts(filteredData);
    };

    const updateSummary = (filteredData) => {
      const pitchTypes = d3.group(filteredData, (d) => d.AutoPitchType);
      const totalPitches = filteredData.length;

      let summaryText = "";
      switch (currentFilter) {
        case "Early":
          summaryText = `Early in counts, ${filteredData[0].Pitcher} relies on ${pitchTypes.size} pitches. `;
          break;
        case "Ahead":
          summaryText = `When he is ahead, ${filteredData[0].Pitcher} relies on ${pitchTypes.size} pitches. `;
          break;
        case "Behind":
          summaryText = `When he is behind, ${filteredData[0].Pitcher} relies on ${pitchTypes.size} pitches. `;
          break;
        default:
          summaryText = `${filteredData[0].Pitcher} relies on ${pitchTypes.size} pitches. `;
      }
      const coloredPitchTypes = Array.from(pitchTypes)
        .map(([type, pitches]) => {
          const percentage = ((pitches.length / totalPitches) * 100).toFixed(1);
          return `<span style="color:${colors(
            type
          )}">${type}</span> (${percentage}%)`;
        })
        .join(" ");

      d3.select(".summary-text").html(summaryText + coloredPitchTypes);
    };

    const updatePitchTypeCharts = (filteredData) => {
      const pitchTypes = d3.group(filteredData, (d) => d.AutoPitchType);
      const totalPitches = filteredData.length;
      const totalRHHPitches = filteredData.filter(
        (d) => d.BatterSide === "Right"
      ).length;
      const totalLHHPitches = filteredData.filter(
        (d) => d.BatterSide === "Left"
      ).length;

      pitchTypes.forEach((pitches, pitchType) => {
        const card = d3.select(`#card-${pitchType.replace(/\s+/g, "-")}`);

        const rhhPitches = pitches.filter((d) => d.BatterSide === "Right");
        const lhhPitches = pitches.filter((d) => d.BatterSide === "Left");

        card
          .select("p")
          .html(
            `${pitches.length} pitches (${(
              (pitches.length / totalPitches) *
              100
            ).toFixed(1)}%)<br>` +
              `vs RHH: ${rhhPitches.length} (${(
                (rhhPitches.length / totalRHHPitches) *
                100
              ).toFixed(1)}%)<br>` +
              `vs LHH: ${lhhPitches.length} (${(
                (lhhPitches.length / totalLHHPitches) *
                100
              ).toFixed(1)}%)`
          );

        const svg = card.select("svg");
        svg.selectAll("*").remove(); // Clear existing chart

        const g = svg
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

        const x = d3.scaleLinear().domain([-3, 3]).range([0, innerWidth]);
        const y = d3.scaleLinear().domain([-1, 6]).range([innerHeight, 0]);

        const plateWidth = 0.7083 * 1.8;
        const plateHeight = plateWidth / 4;
        const plateOffset = 0.8;
        const homePlate = [
          { x: 0, y: 1 - plateOffset },
          { x: plateWidth / 2, y: 1 + plateHeight * 0.75 - plateOffset },
          { x: plateWidth / 2, y: 1 + plateHeight - plateOffset },
          { x: -plateWidth / 2, y: 1 + plateHeight - plateOffset },
          { x: -plateWidth / 2, y: 1 + plateHeight * 0.75 - plateOffset },
          { x: 0, y: 1 - plateOffset },
        ];

        const densityData = d3
          .contourDensity()
          .x((d) => x(d.PlateLocSide))
          .y((d) => y(d.PlateLocHeight))
          .size([innerWidth, innerHeight])
          .bandwidth(20)(pitches);

        const color = d3
          .scaleSequential((t) => d3.interpolateRdBu(1 - t))
          .domain([0, d3.max(densityData, (d) => d.value)]);

        g.insert("g", "g")
          .selectAll("path")
          .data(densityData)
          .enter()
          .append("path")
          .attr("d", d3.geoPath())
          .attr("fill", (d) => color(d.value))
          .attr("opacity", 0.8);

        g.append("path")
          .datum(homePlate)
          .attr("fill", "none")
          .attr("stroke", "black")
          .attr("stroke-width", 2)
          .attr(
            "d",
            d3
              .line()
              .x((d) => x(d.x))
              .y((d) => y(d.y))
          )
          .style("opacity", 0.6);

        g.append("rect")
          .attr("x", x(-0.7083))
          .attr("y", y(3.5))
          .attr("width", x(0.7083) - x(-0.7083))
          .attr("height", y(1.5) - y(3.5))
          .attr("fill", "none")
          .attr("stroke", "black")
          .attr("stroke-width", 2);
      });
    };

    const createInitialLayout = () => {
      const container = d3
        .select(".tables")
        .append("div")
        .style("display", "flex")
        .style("flex-wrap", "wrap")
        .style("justify-content", "center");

      container
        .append("h2")
        .style("width", "100%")
        .style("text-align", "center")
        .attr("class", "table-title")
        .text("2024 Pitch Repertoire");

      // Add filter buttons
      const buttonContainer = container
        .append("div")
        .attr("class", "button-container");

      buttonContainer
        .selectAll("button")
        .data(filterButtons)
        .enter()
        .append("button")
        .text((d) => d)
        .classed("active", (d) => d === currentFilter)
        .on("click", function (event, d) {
          updateCharts(d);
        });

      // Add summary text container
      container
        .append("p")
        .attr("class", "summary-text")
        .style("width", "100%")
        .style("text-align", "center")
        .style("margin-bottom", "20px")
        .style("font-size", "14px");

      // Create containers for each pitch type
      const pitchTypes = d3.group(data, (d) => d.AutoPitchType);
      pitchTypes.forEach((pitches, pitchType) => {
        container
          .append("div")
          .attr("id", `card-${pitchType.replace(/\s+/g, "-")}`)
          .attr("class", "card")
          .style("margin", "10px")
          .style("padding", "10px")
          .call((card) => {
            card
              .append("h3")
              .text(pitchType)
              .style("color", colors(pitchType))
              .style("margin", "5px 5px 5px 5px")
              .style("font-size", "18px");

            card
              .append("p")
              .style("margin", "5px 5px 10px 5px")
              .style("font-size", "12px")
              .style("font-family", "monospace");

            card.append("svg").attr("width", width).attr("height", height);
          });
      });
    };

    createInitialLayout();

    updateCharts("All");
  };

  const createProfilePitcher = (pitchingData) => {
    const profileCard = document.querySelector(".profile-card");

    profileCard.innerHTML = "";

    const mostRecentData = pitchingData[0];

    const nameHeader = document.createElement("h2");
    nameHeader.textContent = mostRecentData.Name;
    profileCard.appendChild(nameHeader);

    const infoLine = document.createElement("p");
    infoLine.classList.add("player-info-line");
    infoLine.innerHTML = `
        <span class="position">P</span> | 
        <span>Bats/Throws: ${mostRecentData.Bats}/${
      mostRecentData.Throws
    }</span> | 
        <span>${mostRecentData.Height}, ${mostRecentData.Weight}LBS</span> | 
        <span>DOB: ${mostRecentData.DOB || "Unknown"}</span><br><span>Status: ${
      mostRecentData.Status || "Unknown"
    } | </span>
        <span>Team: ${mostRecentData.Roster_Team || "Unknown"}</span> | 
        <span>Hometown: ${mostRecentData.Hometown || "Unknown"}</span>
    `;
    profileCard.appendChild(infoLine);

    // Create and append table (similar to your original code)
    const table = document.createElement("table");
    table.classList.add("profile-table");

    const headers = ["Year", "G", "W-L", "FIP", "IP", "SO", "WAR"];
    const headerRow = table.insertRow();

    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    pitchingData.forEach((record) => {
      const row = table.insertRow();
      headers.forEach((columnName) => {
        const cell = row.insertCell();
        let value = record[columnName];
        if (columnName === "WAR") {
          value = value.toFixed(1);
        } else if (columnName === "FIP") {
          value = value.toFixed(2);
        }
        cell.textContent = value;
      });
    });

    profileCard.appendChild(table);
  };

  const createProfileHitter = (hittingData) => {
    const profileCard = document.querySelector(".profile-card");

    profileCard.innerHTML = "";

    const mostRecentData = hittingData[0];

    const nameHeader = document.createElement("h2");
    nameHeader.textContent = mostRecentData.Name;
    profileCard.appendChild(nameHeader);

    const infoLine = document.createElement("p");
    infoLine.classList.add("player-info-line");
    infoLine.innerHTML = `
        <span class="position">P</span> | 
        <span>Bats/Throws: ${mostRecentData.Bats}/${
      mostRecentData.Throws
    }</span> | 
        <span>${mostRecentData.Height}, ${mostRecentData.Weight}LBS</span> | 
        <span>DOB: ${mostRecentData.DOB || "Unknown"}</span><br><span>Status: ${
      mostRecentData.Status || "Unknown"
    } | </span>
        <span>Team: ${mostRecentData.Roster_Team || "Unknown"}</span> | 
        <span>Hometown: ${mostRecentData.Hometown || "Unknown"}</span>
    `;
    profileCard.appendChild(infoLine);

    const table = document.createElement("table");
    table.classList.add("profile-table");

    const headers = ["Year", "BA", "HR", "SB", "WAR"];
    const headerRow = table.insertRow();
    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    hittingData.forEach((record) => {
      const row = table.insertRow();
      headers.forEach((columnName) => {
        const cell = row.insertCell();
        let value = record[columnName];
        if (columnName === "WAR") {
          value = value.toFixed(1);
        } else if (columnName === "BA") {
          value = value.toFixed(3);
        }
        cell.textContent = value;
      });
    });

    profileCard.appendChild(table);
  };
  const createYakkerTableHitter = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#yakkerTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "yakkerTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Yakkertech Statistics";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "yakkerTable";

    const headers = [
      "Pitches",
      "BBE",
      "Barrels",
      "Barrel%",
      "Exit Velocity",
      "Max EV",
      "LaunchAngle",
      "SweetSpot%",
      "xBA",
      "xSLG",
      "xwOBA",
      "xwOBAcon",
      "HardHit%",
      "K%",
      "BB%",
    ];
    const headerRow = table.insertRow();
    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    const row = table.insertRow();
    headers.forEach((columnName) => {
      const cell = row.insertCell();
      let value = savantData[columnName];
      if (
        [
          "Barrel%",
          "SweetSpot%",
          "HardHit%",
          "K%",
          "BB%",
          "LaunchAngle",
          "Exit Velocity",
          "Max EV",
        ].includes(columnName)
      ) {
        value = Number(value).toFixed(1);
      } else if (
        ["xBA", "xSLG", "xwOBA", "xwOBAcon", "wOBA"].includes(columnName)
      ) {
        value = Number(value).toFixed(3);
      }
      cell.textContent = value;
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createBBProfileTable = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#bbProfileTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "bbProfileTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Batted Ball Profile";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "bbProfileTable";

    const headers = [
      "GB%",
      "FB%",
      "LD%",
      "PU%",
      "Pull%",
      "Straight%",
      "Oppo%",
      "Barrel%",
      "Barrel/PA",
      "PullAir%",
    ];
    const headerRow = table.insertRow();
    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    const row = table.insertRow();
    headers.forEach((columnName) => {
      const cell = row.insertCell();
      let value = savantData[columnName];
      value = Number(value).toFixed(1);
      cell.textContent = value;
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createPlateDiscTable = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#discTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "discTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Plate Discipline";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "discTable";

    const headers = [
      "Zone%",
      "Z-Swing%",
      "Z-Contact%",
      "Chase%",
      "O-Contact%",
      "FPS%",
      "Swing%",
      "Whiff%",
      "Meatball%",
      "Meatball Swing%",
    ];
    const headerRow = table.insertRow();
    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    const row = table.insertRow();
    headers.forEach((columnName) => {
      const cell = row.insertCell();
      let value = savantData[columnName];
      value = Number(value).toFixed(1);
      cell.textContent = value;
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createSwingTakeTable = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#swingTakeTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "swingTakeTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Swing/Take";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "swingTakeTable";

    const headers = ["Chase", "Heart", "Shadow", "Waste"];
    const headerRow = table.insertRow();
    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    const row = table.insertRow();
    headers.forEach((columnName) => {
      const cell = row.insertCell();
      let value = savantData[columnName];
      value = Number(value).toFixed(0);
      cell.textContent = value;
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createSpinCharts = (data) => {
    const playerName = data[0]?.Pitcher || "Unknown Player";
    const width = 300;
    const height = 300;
    const margin = { top: 10, right: 40, bottom: 10, left: 10 };
    const outerRadius =
      Math.min(width, height - margin.top - margin.bottom) / 2;
    const innerRadius = outerRadius * 0.4;

    const createClockFaceWithDensity = (svg, title, spinKey) => {
      const chart = svg
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      const angleScale = d3
        .scaleLinear()
        .domain([0, 360])
        .range([0, 2 * Math.PI]);

      const pitchTypes = d3.group(data, (d) => d.AutoPitchType);

      pitchTypes.forEach((pitches, type) => {
        const binData = d3
          .histogram()
          .value((d) => d[spinKey])
          .domain([0, 360])
          .thresholds(72)(pitches);

        binData.forEach((bin) => {
          const startAngle = angleScale(bin.x0);
          const endAngle = angleScale(bin.x1);
          const outerRadiusAdjusted =
            innerRadius +
            (bin.length / pitches.length) * (outerRadius - innerRadius) * 3.25;

          chart
            .append("path")
            .attr(
              "d",
              d3
                .arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadiusAdjusted)
                .startAngle(startAngle)
                .endAngle(endAngle)
            )
            .attr("fill", colors(type))
            .attr("opacity", 0.8);
        });
      });

      chart
        .append("circle")
        .attr("r", innerRadius)
        .attr("fill", "white")
        .attr("stroke", "black")
        .attr("stroke-width", 2);

      for (let i = 1; i <= 12; i++) {
        const angle = (i - 3) * 30 * (Math.PI / 180);

        chart
          .append("text")
          .attr("x", Math.cos(angle) * (innerRadius - 20))
          .attr("y", Math.sin(angle) * (innerRadius - 20))
          .attr("text-anchor", "middle")
          .attr("dominant-baseline", "central")
          .style("font-size", "10px")
          .text(i);
      }
    };

    const container = d3
      .select(".tables")
      .append("div")
      .style("display", "flex")
      .style("justify-content", "space-evenly")
      .style("width", "100%")
      .style("font-family", "Arial, sans-serif");

    const createChart = (title, spinKey) => {
      const chartContainer = container
        .append("div")
        .style("border", "1px solid #ccc")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .style("background-color", "#ffffff")
        .style("width", `${width + margin.left + margin.right}px`);

      chartContainer.append("div").style("text-align", "left")
        .html(`<strong style="font-size: 18px;">${playerName}</strong><br>
               <span style="font-size: 14px; color: #666;">${title}</span>`);

      const svg = chartContainer
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height);

      createClockFaceWithDensity(svg, title, spinKey);

      const legend = svg
        .append("g")
        .attr(
          "transform",
          `translate(${width - margin.right + 10}, ${margin.top + 10})`
        );
      const pitchTypes = Array.from(new Set(data.map((d) => d.AutoPitchType)));
      const lineHeight = 20;

      pitchTypes.forEach((type, i) => {
        const legendRow = legend
          .append("g")
          .attr("transform", `translate(0, ${i * lineHeight})`);

        legendRow
          .append("circle")
          .attr("cx", 0)
          .attr("cy", -1.5)
          .attr("r", 3)
          .attr("fill", colors(type));

        legendRow
          .append("text")
          .attr("x", 10)
          .attr("y", 0)
          .attr("text-anchor", "start")
          .attr("alignment-baseline", "middle")
          .style("font-size", "10px")
          .text(type);
      });
    };

    createChart("Spin-Based Movement by Pitch Type", "derivedSpinAxis");
    createChart("Observed Movement by Pitch Type", "SpinAxis");
  };

  const createSpinTable = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#spinTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "spinTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Spin Based Movement";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "spinTable";

    const headers = [
      "Pitch Type",
      "SpinRate",
      "Efficiency",
      "HorzBreak",
      "IVB",
      "SpinBased",
      "Observed",
      "Deviation",
    ];

    const headerRow = table.insertRow();
    headers.forEach((headerText, index) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    savantData.forEach((record) => {
      const row = table.insertRow();
      headers.forEach((columnName) => {
        const cell = row.insertCell();
        let value = record[columnName];
        if (
          [
            "Barrel%",
            "SweetSpot%",
            "HardHit%",
            "K%",
            "BB%",
            "LaunchAngle",
            "EV",
            "Max EV",
            "Whiff%",
            "Chase%",
            "PutAway%",
            "LA",
            "RV",
            "Velo",
            "Extension",
            "Zone%",
            "Efficiency",
            "HorzBreak",
            "IVB",
          ].includes(columnName)
        ) {
          value = Number(value).toFixed(1);
        } else if (
          ["xBA", "xSLG", "xwOBA", "xwOBAcon", "SLG", "wOBA", "BA"].includes(
            columnName
          )
        ) {
          value = Number(value).toFixed(3);
        } else if (["SpinRate"].includes(columnName)) {
          value = Number(value).toFixed(0);
        }
        cell.textContent = value;
      });
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createStuffTable = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#stuffTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "stuffTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Pitch Models and Value";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "stuffTable";

    const headers = [
      "Pitch Type",
      "Pitches",
      "xRV",
      "RV",
      "RV/100",
      "xWhiff",
      "xSwing",
      "Stuff+",
    ];

    // Create header row
    const headerRow = table.insertRow();
    headers.forEach((header) => {
      const th = document.createElement("th");
      th.textContent = header;
      headerRow.appendChild(th);
    });

    // Create data rows
    savantData.forEach((record) => {
      const row = table.insertRow();
      headers.forEach((columnName) => {
        const cell = row.insertCell();
        let value = record[columnName];
        if (
          [
            "Barrel%",
            "SweetSpot%",
            "HardHit%",
            "K%",
            "BB%",
            "LaunchAngle",
            "EV",
            "Max EV",
            "Whiff%",
            "Chase%",
            "PutAway%",
            "LA",
            "RV",
            "RV/100",
            "Velo",
            "Extension",
            "Zone%",
            "Efficiency",
            "xCalledStrike",
            "xWhiff",
            "xCSW",
            "xSwing",
            "xRV",
          ].includes(columnName)
        ) {
          value = Number(value).toFixed(1);
        } else if (
          ["xBA", "xSLG", "xwOBA", "xwOBAcon", "SLG", "wOBA", "BA"].includes(
            columnName
          )
        ) {
          value = Number(value).toFixed(3);
        } else if (["SpinRate", "Stuff+"].includes(columnName)) {
          value = Number(value).toFixed(0);
        }
        cell.textContent = value;
      });
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createxHRTable = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#xHRTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "xHRTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Expected Home Runs";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "discTable";

    const headers = [
      "xHR",
      "HR",
      "HR-xHR",
      "Front Row Joes",
      "Mostly Gone",
      "No Doubters",
      "ND%",
    ];
    const headerRow = table.insertRow();
    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    const row = table.insertRow();
    headers.forEach((columnName) => {
      const cell = row.insertCell();
      let value = savantData[columnName];
      value = Number(value).toFixed(0);
      cell.textContent = value;
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createPitchTrackingTable = (pitchTrackingData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#pitchTrackingContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "pitchTrackingContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Pitch Tracking";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "pitchTracking";

    const headers = [
      "Pitch Type",
      "Pitches",
      "BBE",
      "PA",
      "AB",
      "xwOBAcon",
      "xwOBA",
      "wOBA",
      "xBA",
      "BA",
      "xSLG",
      "SLG",
      "Barrel%",
      "HardHit%",
      "Whiff%",
      "1B",
      "2B",
      "3B",
      "HR",
      "SO",
      "BB",
    ];
    const headerRow = table.insertRow();
    headers.forEach((headerText, index) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    pitchTrackingData.forEach((record) => {
      const row = table.insertRow();
      headers.forEach((columnName) => {
        const cell = row.insertCell();
        let value = record[columnName];
        if (
          [
            "Barrel%",
            "SweetSpot%",
            "HardHit%",
            "K%",
            "BB%",
            "LaunchAngle",
            "Exit Velocity",
            "Max EV",
            "Whiff%",
          ].includes(columnName)
        ) {
          value = Number(value).toFixed(1);
        } else if (
          ["xBA", "xSLG", "xwOBA", "xwOBAcon", "SLG", "wOBA", "BA"].includes(
            columnName
          )
        ) {
          value = Number(value).toFixed(3);
        }
        cell.textContent = value;
      });
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };
  const createPitchTrackingPitcherTable = (pitchTrackingData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#pitchTrackingContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "pitchTrackingContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Pitch Tracking";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "pitchTracking";

    const headers = [
      "Pitch Type",
      "Pitches",
      "vs RHB",
      "vs LHB",
      "%",
      "PA",
      "AB",
      "xwOBAcon",
      "xwOBA",
      "wOBA",
      "xBA",
      "BA",
      "xSLG",
      "SLG",
      "Barrel%",
      "HardHit%",
      "Whiff%",
      "Chase%",
      "PutAway%",
      "CSW%",
      "InZone%",
      "Velo",
      "Spin",
      "Extension",
    ];

    // Create thead and tbody
    const thead = table.createTHead();
    const tbody = table.createTBody();

    // Create header row
    const headerRow = thead.insertRow();
    headers.forEach((headerText, index) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    // Populate table body
    pitchTrackingData.forEach((record) => {
      const row = tbody.insertRow();
      headers.forEach((columnName, index) => {
        const cell = row.insertCell();
        let value = record[columnName];
        if (
          [
            "Barrel%",
            "SweetSpot%",
            "HardHit%",
            "K%",
            "BB%",
            "LaunchAngle",
            "EV",
            "Max EV",
            "Whiff%",
            "Chase%",
            "PutAway%",
            "LA",
            "RV",
            "Velo",
            "%",
            "Extension",
            "Whiff%",
            "CSW%",
            "InZone%",
          ].includes(columnName)
        ) {
          value = Number(value).toFixed(1);
        } else if (
          ["xBA", "xSLG", "xwOBA", "xwOBAcon", "SLG", "wOBA", "BA"].includes(
            columnName
          )
        ) {
          value = Number(value).toFixed(3);
        } else if (["Spin"].includes(columnName)) {
          value = Number(value).toFixed(0);
        }
        cell.textContent = value;
      });
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  const createYakkerTablePitcher = (savantData) => {
    const tables = document.querySelector(".tables");
    const existingContainer = tables.querySelector("#yakkerTableContainer");
    if (existingContainer) tables.removeChild(existingContainer);

    const container = document.createElement("div");
    container.id = "yakkerTableContainer";
    container.classList.add("table-container");

    const title = document.createElement("h2");
    title.textContent = "Yakkertech Statistics";
    title.classList.add("table-title");

    const scrollContainer = document.createElement("div");
    scrollContainer.classList.add("table-scroll-container");

    const table = document.createElement("table");
    table.classList.add("tablelist-table");
    table.id = "yakkerTable";

    const headers = [
      "Pitches",
      "BBE",
      "Barrels",
      "Barrel%",
      "Exit Velocity",
      "Max EV",
      "LaunchAngle",
      "SweetSpot%",
      "xBA",
      "xSLG",
      "xwOBA",
      "xwOBAcon",
      "wOBA",
      "HardHit%",
      "K%",
      "BB%",
      "xERA",
    ];
    const headerRow = table.insertRow();
    headers.forEach((headerText) => {
      const th = document.createElement("th");
      th.textContent = headerText;
      headerRow.appendChild(th);
    });

    const row = table.insertRow();
    headers.forEach((columnName) => {
      const cell = row.insertCell();
      let value = savantData[columnName];
      if (
        [
          "Barrel%",
          "SweetSpot%",
          "HardHit%",
          "K%",
          "BB%",
          "LaunchAngle",
          "Exit Velocity",
          "Max EV",
        ].includes(columnName)
      ) {
        value = Number(value).toFixed(1);
      } else if (
        ["xBA", "xSLG", "xwOBA", "xwOBAcon", "wOBA"].includes(columnName)
      ) {
        value = Number(value).toFixed(3);
      } else if (["xERA"].includes(columnName)) {
        value = Number(value).toFixed(2);
      }
      cell.textContent = value;
    });

    container.appendChild(title);
    scrollContainer.appendChild(table);
    container.appendChild(scrollContainer);
    tables.appendChild(container);
  };

  return (
    <div className="Profile">
      <Header />
      <div className="container">
        <div className="profile-card profile-column">
          <div className="profile-info">
            <h1 className="profile-title">{name}</h1>
          </div>
        </div>
        <div className="profile-column">
          <div id="percentileChart"></div>
        </div>
        <div className="profile-column">
          <div id="chart"></div>
          <div id="otherChart"></div>
        </div>
      </div>
      <div className="tables-outer-container">
        <div className="tables-container">
          <div className="tables">{/* Your tables go here */}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
