import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Visualization.css";

function Visualization() {
  const [activeVisualization, setActiveVisualization] = useState(null);
  const [catchers, setCatchers] = useState([]);

  useEffect(() => {
    if (activeVisualization === "catcherFraming") {
      fetch("/api/catchers")
        .then((response) => response.json())
        .then((data) => setCatchers(data))
        .catch((error) => console.error("Error fetching catchers:", error));
    }
  }, [activeVisualization]);

  return (
    <div className="visualization-page">
      <Header />
      <main className="visualization-content">
        {!activeVisualization ? (
          <div className="visualization-grid">
            <VisualizationCard
              title="Catcher Framing"
              description="Select a catcher to see where he frames pitches best and where he struggles."
              icon="🖼️"
            />
            <VisualizationCard
              title="Batter vs Pitcher Matchup"
              description="Query batter vs. pitcher matchups, see pitch types and locations."
              icon="⚾"
            />
            <VisualizationCard
              title="Win Probability"
              description="Visualize how win probability changed for any given game over the 2024 season."
              icon="📈"
            />
          </div>
        ) : (
          <div className="active-visualization">
            {/* {activeVisualization === "catcherFraming" && (
              <CatcherFraming catchers={catchers} />
            )} */}
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
}

function VisualizationCard({ title, description, icon, onClick }) {
  return (
    <div className="visualization-card">
      <div className="visualization-info">
        <h2>
          {icon} {title}
        </h2>
        <p>{description}</p>
        <button className="visualization-button" onClick={onClick}>
          View
        </button>
      </div>
    </div>
  );
}

export default Visualization;
