import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import "./Leaderboards.css";

function Leaderboards() {
  const [selectedTable, setSelectedTable] = useState("hitting_war");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableTables, setAvailableTables] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [selectedYear, setSelectedYear] = useState("All");

  const availableYears = ["All", "2024", "2023"];
  const warAvailableYears = ["All", "2024", "2023", "2022", "2021"];
  const stuffColumns = [
    "Pitcher",
    "Team",
    "Year",
    "Pitches",
    "FB+",
    "SI+",
    "CT+",
    "CB+",
    "SL+",
    "CH+",
    "SPL+",
    "KN+",
    "Stuff+",
  ];
  const locationColumns = [
    "Pitcher",
    "Team",
    "Year",
    "Pitches",
    "FB+",
    "SI+",
    "CT+",
    "CB+",
    "SL+",
    "CH+",
    "SPL+",
    "KN+",
    "Location+",
  ];
  const pitchingColumns = [
    "Pitcher",
    "Team",
    "Year",
    "Pitches",
    "FB+",
    "SI+",
    "CT+",
    "CB+",
    "SL+",
    "CH+",
    "SPL+",
    "KN+",
    "Pitching+",
  ];
  const catcherColumns = [
    "Catcher",
    "Team",
    "Year",
    "Pitches",
    "Framing Runs",
    "Strike%",
    "Zone 11",
    "Zone 12",
    "Zone 13",
    "Zone 14",
    "Zone 16",
    "Zone 17",
    "Zone 18",
    "Zone 19",
  ];
  const umpColumns = [
    "Umpire",
    "Pitches",
    "TotalPitchAccuracy",
    "MissedCalls",
    "CalledStrike%",
    "CalledBall%",
    "Consistency",
  ];
  const evBarrelsColumns = [
    "Name",
    "Team",
    "BBE",
    "LA",
    "SweetSpot%",
    "Max EV",
    "Avg EV",
    "EV50",
    "FB/LD",
    "Max Distance",
    "Avg HR",
    "95+ MPH",
    "HardHit%",
    "Barrels",
    "Barrel%",
    "Brls/PA",
  ];
  const ptColumns = [
    "Player",
    "Team",
    "C",
    "1B",
    "2B",
    "3B",
    "SS",
    "LF",
    "CF",
    "RF",
    "PH",
    "PR",
  ];
  const xHRColumns = [
    "Batter",
    "Actual HR",
    "Evansville Otters",
    "Florence Y'alls",
    "Gateway Grizzlies",
    "Joliet Slammers",
    "Lake Erie Crushers",
    "New England Knockouts",
    "New Jersey Jackals",
    "New York Boulders",
    "Ottawa Titans",
    "Quebec Capitales",
    "Schaumburg Boomers",
    "Sussex County Miners",
    "Tri-City ValleyCats",
    "Trois-Rivieres Aigles",
    "Washington Wild Things",
    "Windy City ThunderBolts",
  ];
  const teamLogoMap = {
    "Ottawa Titans": "/logos/titans.png",
    "Schaumburg Boomers": "/logos/boomers.png",
    "Gateway Grizzlies": "/logos/grizzlies.png",
    "Lake Erie Crushers": "/logos/crushers.png",
    "Aigles de Trois-Rivières": "/logos/aigles.png",
    "Équipe Québec": "/logos/capitales.png",
    "Florence Y'alls": "/logos/yalls.png",
    "Florence Y'Alls": "/logos/yalls.png",
    "Evansville Otters": "/logos/otters.png",
    "Sussex County Miners": "/logos/miners.png",
    "Windy City Thunderbolts": "/logos/thunderbolts.png",
    "Windy City ThunderBolts": "/logos/thunderbolts.png",
    "Washington Wild Things": "/logos/wildthings.png",
    "Joliet Slammers": "/logos/slammers.png",
    "Capitales de Québec": "/logos/capitales.png",
    "Quebec Capitales": "/logos/capitales.png",
    "New Jersey Jackals": "/logos/jackals.png",
    "New York Boulders": "/logos/boulders.png",
    "New England Knockouts": "/logos/knockouts.png",
    "Southern Illinois Miners": "/logos/miners.png",
    "Tri-City ValleyCats": "/logos/valleycats.png",
    "Trois-Rivieres Aigles": "/logos/aigles.png",
    "Empire State Greys": "/logos/greys.png",
    "Trois-Rivières Aigles": "/logos/aigles.png",
    "Québec Capitales": "/logos/capitales.png",
    "Tri-City Valleycats": "/logos/valleycats.png",
    "Washington WildThings": "/logos/wildthings.png",
  };
  const hittingWARColumns = [
    "Name",
    "Age",
    "Team",
    "Year",
    "PA",
    "H",
    "2B",
    "3B",
    "HR",
    "SB",
    "BA",
    "ISO",
    "wOBA",
    "wSB",
    "BAbip",
    "K%",
    "BB%",
    "OPS+",
    "wRC+",
    "WAR",
  ];
  const pitchingWARColumns = [
    "Name",
    "Age",
    "Team",
    "Year",
    "IP",
    "G",
    "W-L",
    "SV",
    "SO",
    "BB",
    "H",
    "HR",
    "SO/W",
    "SO9",
    "BB9",
    "RA9",
    "FIP",
    "ERA",
    "WAR",
  ];

  const tableNameMapping = {
    stuff_leaders: "Stuff+",
    location_leaders: "Location+",
    pitching_leaders: "Pitching+",
    catcher_framing: "Catcher Framing",
    umpires: "Ump Leaderboard",
    ev_barrels: "Exit Velocity & Barrels Leaderboard",
    park_hr: "xHR by Park",
    playing_time: "Playing Time",
    hitting_war: "WAR (Hitters)",
    pitching_war: "WAR (Pitchers)",
  };

  const tableDescriptions = {
    stuff_leaders:
      "A table displaying leaders in Stuff+ metrics. Stuff+ measures the quality of a pitch based on qualities like velocity, movement, arm angle, etc.",
    location_leaders:
      "This table shows leaders in Location+ metrics. Location+ evaluates a pitcher's ability to consistently hit pitcher advantageous spots. It does not take into account intended zone.",
    pitching_leaders:
      "The Pitching+ leaderboard combines Stuff+ and Location+ to give an overall picture of a pitcher's effectiveness. It is not merely a weighted average, but in fact a seperate model that takes into account count, location, pitch quality, etc.",
    catcher_framing:
      "Catcher framing is quantified by looking at the probability of a pitch being called a strike by an average FL ump. If a pitch is likely to be called a ball but was called a strike, that catcher earned the run value of a strike (.125). If he loses a strike, substract the run value of one. Strike% is the rate of stolen strikes to opportunities. Framing runs are pitcher and park adjusted.",
    umpires:
      "A table displaying how Frontier League umpires have done thus far. Data spans from the beginning of the 2024 season to the present.",
    ev_barrels: "A table displaying leaders in Yakkertech metrics for hitters.",
    park_hr:
      "How many home runs would a player hit if he played all his games in a certain ball park? This table answers that very question.",
    playing_time:
      "The amount of games a player has played at each position. This is accounted for in WAR calculations. Assumes that an appearance in a game is 9 innings.",
    hitting_war:
      "A comprehensive measure of a hitter's total contributions to their team in the Frontier League.",
    pitching_war:
      "A comprehensive measure of a pitcher's total contributions to their team in the Frontier League.",
  };

  const columnConfig = {
    "Stuff+": { min: 50, max: 150, mid: 100 },
    "Location+": { min: 50, max: 150, mid: 100 },
    "Pitching+": { min: 50, max: 150, mid: 100 },
    "Framing Runs": { min: -10, max: 10, mid: 0 },
    "FB+": { min: 50, max: 150, mid: 100 },
    "SI+": { min: 50, max: 150, mid: 100 },
    "CT+": { min: 50, max: 150, mid: 100 },
    "CB+": { min: 50, max: 150, mid: 100 },
    "SL+": { min: 50, max: 150, mid: 100 },
    "CH+": { min: 50, max: 150, mid: 100 },
    "SPL+": { min: 50, max: 150, mid: 100 },
    "KN+": { min: 50, max: 150, mid: 100 },
    WAR: { min: -2, max: 7, mid: 0.5 },
  };

  const gradientColumns = [
    "Stuff+",
    "Location+",
    "Pitching+",
    "Framing Runs",
    "WAR",
  ];

  const getDisplayName = (tableName) => {
    return (
      tableNameMapping[tableName] || tableName.replace(/_/g, " ").toUpperCase()
    );
  };

  useEffect(() => {
    fetchAvailableTables();
  }, []);

  useEffect(() => {
    if (selectedTable) {
      fetchTableData(selectedTable, selectedYear);
    }
  }, [selectedTable, selectedYear]);

  const fetchAvailableTables = async () => {
    try {
      const response = await fetch("/api/available_tables");
      if (!response.ok) {
        throw new Error("Failed to fetch available tables");
      }
      const tables = await response.json();
      setAvailableTables(tables);
    } catch (error) {
      setError("Failed to fetch available tables");
    }
  };

  const fetchTableData = async (tableName, year) => {
    setLoading(true);
    setError(null);
    try {
      const yearParam = year === "All" ? "" : `?year=${year}`;
      const response = await fetch(`/api/table_data/${tableName}${yearParam}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      let processedData = processData(result, tableName);

      if (tableName === "hitting_war") {
        processedData = processedData.filter((player) => player.PA > 20);
      }
      if (tableName === "pitching_war") {
        processedData = processedData.filter((player) => player.IP > 3);
      }

      setData(processedData || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(`Failed to fetch data for ${tableName}: ${error.message}`);
      setData([]);
      setLoading(false);
    }
  };

  const processData = (data, tableName) => {
    return data.map((row) => {
      const newRow = { ...row };
      for (let key in newRow) {
        if (typeof newRow[key] === "number") {
          if (
            ["stuff_leaders", "location_leaders", "pitching_leaders"].includes(
              tableName
            )
          ) {
            newRow[key] = Math.round(newRow[key]);
          } else if (["pitching_war", "hitting_war"].includes(tableName)) {
            if (["BA", "wOBA", "BAbip", "ISO", "wSB"].includes(key)) {
              newRow[key] = Number(newRow[key].toFixed(3));
            } else if (["FIP", "ERA", "RA9"].includes(key)) {
              newRow[key] = Number(newRow[key].toFixed(2));
            } else if (
              ["K%", "BB%", "WAR", "SO/W", "BB9", "SO9", "IP"].includes(key)
            ) {
              newRow[key] = Number(newRow[key].toFixed(1));
            } else {
              newRow[key] = Number(newRow[key].toFixed(0));
            }
          } else if (["catcher_framing"].includes(tableName)) {
            if (key === "Framing Runs") {
              newRow[key] = Number(newRow[key].toFixed(1));
            } else if (key === "Strike%" || key.startsWith("Zone")) {
              newRow[key] = Number(newRow[key].toFixed(1));
            }
          } else if (tableName === "umpires") {
            if (
              key === "TotalPitchAccuracy" ||
              key === "Consistency" ||
              key.endsWith("%")
            ) {
              newRow[key] = Number(newRow[key].toFixed(1));
            }
          } else if (tableName === "ev_barrels") {
            if (
              key === "LA" ||
              key === "EV50" ||
              key === "FB/LD" ||
              key.endsWith("%") ||
              key.endsWith("EV") ||
              key === "Brls/PA"
            ) {
              newRow[key] = Number(newRow[key].toFixed(1));
            } else {
              newRow[key] = Number(newRow[key].toFixed(0));
            }
          }
        }
      }
      return newRow;
    });
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    if (sortConfig.key) {
      return [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  };

  const sortedData = getSortedData();

  const columns =
    selectedTable === "stuff_leaders"
      ? stuffColumns
      : selectedTable === "pitching_leaders"
      ? pitchingColumns
      : selectedTable === "location_leaders"
      ? locationColumns
      : selectedTable === "catcher_framing"
      ? catcherColumns
      : selectedTable === "umpires"
      ? umpColumns
      : selectedTable === "ev_barrels"
      ? evBarrelsColumns
      : selectedTable === "park_hr"
      ? xHRColumns
      : selectedTable === "playing_time"
      ? ptColumns
      : selectedTable === "pitching_war"
      ? pitchingWARColumns
      : selectedTable === "hitting_war"
      ? hittingWARColumns
      : data && data.length > 0
      ? Object.keys(data[0])
      : [];

  const getTextColor = (backgroundColor) => {
    const rgb = backgroundColor.match(/\d+/g).map(Number);
    const luminance = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;

    return luminance > 0.5 ? "black" : "white";
  };

  const getColorStyle = (value, min, max, mid) => {
    let backgroundColor;

    if (value > mid) {
      const intensity = Math.round(255 * (1 - (value - mid) / (max - mid)));
      backgroundColor = `rgb(255, ${intensity}, ${intensity})`;
    } else if (value < mid) {
      const intensity = Math.round(255 * (1 - (mid - value) / (mid - min)));
      backgroundColor = `rgb(${intensity}, ${intensity}, 255)`;
    } else {
      backgroundColor = "rgb(255, 255, 255)";
    }

    const textColor = getTextColor(backgroundColor);

    return {
      color: textColor,
      backgroundColor: backgroundColor,
    };
  };

  if (loading)
    return (
      <div className="loading-screen">
        <svg xmlns="http://www.w3.org/2000/svg" width="588" height="360">
          <g fill="none" fillRule="evenodd" transform="translate(13 13)">
            <path
              className="field"
              stroke="#2C82C9"
              strokeWidth="25"
              d="M89.5 137.5S142.533 0 280.262 0c137.73 0 190.763 137.5 190.763 137.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              className="base base-2"
              width="30"
              height="30"
              x="265.485"
              y="43.485"
              fill="#2583CA"
              transform="rotate(45 280.485 58.485)"
            />
            <rect
              className="base base-4"
              width="30"
              height="30"
              x="265.485"
              y="255.485"
              fill="#FF9F36"
              transform="rotate(45 280.485 270.485)"
            />
            <path
              fill="#DADADA"
              d="M205.078 178.144l64.492 64.49c2.676-2.976 6.556-4.848 10.874-4.848 4.29 0 8.147 1.848 10.82 4.79l65.2-65.197c-5.17-2.253-8.784-7.408-8.784-13.407 0-5.64 3.193-10.532 7.87-12.97L290.6 86.056c-2.63 2.54-6.21 4.105-10.156 4.105-3.974 0-7.578-1.586-10.213-4.16L205 151.23c5.253 2.217 8.94 7.415 8.94 13.474 0 6.03-3.65 11.205-8.862 13.44z"
            />
            <rect
              className="base base-1"
              width="30"
              height="30"
              x="366.213"
              y="151.213"
              fill="#2583CA"
              transform="rotate(45 381.213 166.213)"
            />
            <rect
              className="base base-3"
              width="30"
              height="30"
              x="165.213"
              y="150.213"
              fill="#2583CA"
              transform="rotate(45 180.213 165.213)"
            />
            <polygon
              stroke="#2C82C9"
              strokeWidth="25"
              points="0.501260438 47.7928943 279.794768 329.50062 562.207099 46.4974925 280.499373 328.791"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    );

  return (
    <div className="leaderboard">
      <Header />
      <div className="leaderboard-container">
        <div className="leaderboard-header">
          <h1>{getDisplayName(selectedTable)}</h1>
          <div className="filter-options">
            <select
              className="table-select"
              value={selectedTable}
              onChange={(e) => setSelectedTable(e.target.value)}
            >
              {availableTables.map((table) => (
                <option key={table} value={table}>
                  {getDisplayName(table)}
                </option>
              ))}
            </select>
            {!["playing_time", "umpires", "park_hr", "ev_barrels"].includes(
              selectedTable
            ) && (
              <select
                className="year-select"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {(selectedTable === "hitting_war" ||
                selectedTable === "pitching_war"
                  ? warAvailableYears
                  : availableYears
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            )}
            <CSVLink
              data={sortedData}
              filename={`${selectedTable}_${selectedYear}.csv`}
            >
              <button className="download-btn">Download CSV</button>
            </CSVLink>
          </div>
        </div>

        {tableDescriptions[selectedTable] && (
          <div className="table-description">
            <p>{tableDescriptions[selectedTable]}</p>
            {selectedTable === "hitting_war" && (
              <p>
                Note: WAR has been positionally adjusted as of 2024 on, and has
                always been park adjusted. Baserunning is taken into account via
                wSB only. All constants used to calculate WAR are available in
                Guts.
              </p>
            )}
          </div>
        )}

        {sortedData && sortedData.length > 0 ? (
          <div className="leaderboard-table-container">
            <table className="leaderboard-table">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column}
                      onClick={() => sortData(column)}
                      className="sortable-column"
                    >
                      {["park_hr", "playing_time"].includes(selectedTable) &&
                      teamLogoMap[column] ? (
                        <img
                          src={teamLogoMap[column]}
                          alt={column}
                          title={column}
                          className="team-logo"
                        />
                      ) : (
                        column.replace(/_/g, " ")
                      )}
                      {sortConfig.key === column && (
                        <span>
                          {sortConfig.direction === "ascending" ? " ▲" : " ▼"}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedData.map((row, index) => (
                  <tr key={index}>
                    {columns.map((column) => (
                      <td
                        key={column}
                        className={
                          gradientColumns.includes(column) ? "heatmap-cell" : ""
                        }
                        style={
                          gradientColumns.includes(column)
                            ? getColorStyle(
                                row[column],
                                columnConfig[column].min,
                                columnConfig[column].max,
                                columnConfig[column].mid
                              )
                            : {}
                        }
                      >
                        {column === "Team" && teamLogoMap[row[column]] ? (
                          <img
                            src={teamLogoMap[row[column]]}
                            alt={row[column]}
                            title={row[column]}
                            className="team-logo"
                          />
                        ) : selectedTable === "park_hr" &&
                          column !== "Batter" &&
                          column !== "Actual HR" ? (
                          <span
                            style={{
                              color:
                                row[column] > row["Actual HR"]
                                  ? "red"
                                  : row[column] < row["Actual HR"]
                                  ? "blue"
                                  : "black",
                            }}
                          >
                            {row[column]}
                          </span>
                        ) : (
                          row[column]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-data">No data available for this table.</div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Leaderboards;
